import React, { useEffect, useRef } from "react";
import "react-slideshow-image/dist/styles.css";
import "../../../App.css";

const SecondSemester = () => {
  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          const navLink = document.querySelector(`a[href="#${id}"]`);
          if (navLink) { // Check if navLink is not null
            if (entry.isIntersecting) {
              navLink.classList.remove("text-blue-300");
              navLink.classList.add("text-abiPink");
              entry.target.classList.add("visible");
            } else {
              navLink.classList.remove("text-abiPink");
              navLink.classList.add("text-blue-300");
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);
  const handleNavClick = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative bg-gray-100 text-black px-36">
<div className="fixed right-0 mr-11 top-1/2 transform -translate-y-1/2 w-48 bg-abiBlue text-white p-4 overflow-y-auto rounded-md">
  <h2 className="text-lg font-bold mb-4 text-slate-200 text-center">
    Navigation
  </h2>
  <ul className="space-y-2 text-sm">
    <li>
      <a
        href="#floorplan"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "floorplan")}
      >
        Floor Plan
      </a>
    </li>
    <li>
      <a
        href="#constructionsite"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "constructionsite")}
      >
        Construction Site Plan
      </a>
    </li>
    <li>
      <a
        href="#crosssection"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "crosssection")}
      >
        Cross Section
      </a>
    </li>
    <li>
      <a
        href="#details"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "details")}
      >
        Details
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#ridge"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "ridge")}
          >
            Ridge
          </a>
        </li>
        <li>
          <a
            href="#externalwallroof"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "externalwallroof")}
          >
            External Wall + Roof
          </a>
        </li>
        <li>
          <a
            href="#Internalloadbearingwalldeck"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "Internalloadbearingwalldeck")}
          >
            Internal Load Bearing Wall + Deck
          </a>
        </li>
        <li>
          <a
            href="#stairslightweightfloorpartition"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "stairslightweightfloorpartition")}
          >
            Stairs Light Weight Floor Partition
          </a>
        </li>
        <li>
          <a
            href="#stairsgroundslabbasement"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "stairsgroundslabbasement")}
          >
            Stairs Ground Slab Basement
          </a>
        </li>
        <li>
          <a
            href="#aesthetic"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "aesthetic")}
          >
            Internal non-load bearing wall + light weight floor partition
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a
        href="#fireanalysis"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "fireanalysis")}
      >
        Fire
      </a>
    </li>
  </ul>
</div>



      <div className="pr-56 pl-8 py-8">
        <h1 className="text-3xl font-bold mb-4 text-black text-center mt-8">
          Second Semester
        </h1>
        <p className="mb-8 text-center">
          During the second semester we designed a 2 storey house with a
          basement for a family of four. They request a modern Scandinavian
          inspired house with a focus on simple structures and traditional
          materials including brick work and wood. Our design which provides
          simplicity and functionality with the use of clean lines, a
          traditional pitched roof and brick walls, as well as a heavy partition
          between the basement and ground floor with a lighter floor partition
          above it.
        </p>
        <div
          className="mb-4 transition-opacity"
          id="floorplan"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Floor Plan
          </h2>
          <img
            src={`${process.env.PUBLIC_URL}/2nd semester/Floor Plan.jpg`}
            alt="Floor Plan"
            className="w-full h-auto"
          />
        </div>
        <div className="flex flex-wrap gap-8 mb-8 mt-20">
          <div
            className="flex-1 w-96 h-96 transition-opacity"
            id="constructionsite"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Construction Site Plan
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/2nd semester/Construction Site Plan.jpg`}
              alt="Construction Site Plan"
              className="w-full h-auto"
            />
          </div>

          <div
            className="flex-1 w-96 h-96 transition-opacity"
            id="crosssection"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Cross Section
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/2nd semester/Cross Section.jpg`}
              alt="Cross Section"
              className="w-full h-auto"
            />
          </div>
        </div>

        <div
          id="details"
          className="mt-56 transition-opacity"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <h2 className="text-2xl font-bold text-center">Details</h2>
          <h3 className="text-xl font-semibold mb-1 text-center">
            Climate Shell - wind tight, water tight
          </h3>
          <div className="grid grid-cols-2 gap-4">
            <div
              className="col-span-1 transition-opacity"
              id="ridge"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">Ridge</h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Climate Shell - wind tight, water tight/Ridge.jpg`}
                alt="Ridge"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="externalwallroof"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                External wall + roof
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Climate Shell - wind tight, water tight/External wall + roof.jpg`}
                alt="External wall + roof"
                className="w-full h-auto"
              />
            </div>
          </div>
          <h3 className="text-xl font-semibold mt-5 mb-2 text-center">
            Load Transferring
          </h3>
          <div className="grid grid-cols-3 gap-4">
            <div
              className="col-span-1 transition-opacity"
              id="Internalloadbearingwalldeck"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Internal load bearing wall + deck
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Load Transferring/Internal load bearing wall + deck.jpg`}
                alt="Internal load bearing wall + deck"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="externalwallfoundation"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                External wall + foundation
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Load Transferring/External wall + foundation.jpg`}
                alt="External wall + foundation"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="externalwalldeck"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                External wall + deck
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Load Transferring/External wall + deck.jpg`}
                alt="External wall + deck"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-3">
            <div
              className="col-span-1 transition-opacity"
              id="stairslightweightfloorpartition"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Stairs light weight floor partition
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Load Transferring/Stairs light weight floor partition.jpg`}
                alt="Stairs light weight floor partition"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="stairsgroundslabbasement"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Stairs ground slab basement
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Details/Load Transferring/Stairs ground slab basement.jpg`}
                alt="Stairs ground slab basement"
                className="w-full h-auto"
              />
            </div>
          </div>
          <h3 className="text-xl font-semibold mt-5 mb-2 text-center">
            Aesthetic
          </h3>
          <div className="grid grid-cols-1 gap-4">
            <div
              className="col-span-1 flex justify-center items-center flex-col transition-opacity"
              id="aesthetic"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-0 text-center">
                Internal non-load bearing wall + light weight floor partition
              </h2>
              <div className="flex items-start justify-center">
                <img
                  src={`${process.env.PUBLIC_URL}/2nd semester/Details/Aesthetic/Internal non-load bearing wall + light weight floor partition.jpg`}
                  alt="Internal non-load bearing wall + light weight floor partition"
                  className="w-auto h-2/3"
                />
              </div>
            </div>
          </div>

          <h3 className="text-2xl font-bold mb-0 text-center -mt-72 ">
            Fire
          </h3>
          <div className="grid grid-cols-2 gap-4 transition-opacity" id="fire">
            <div
              className="col-span-1"
              id="fireanalysis"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Fire Analysis
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Fire/Fire Analysis.jpg`}
                alt="Fire Analysis"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1"
              id="fireplan"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Fire Plan
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/2nd semester/Fire/Fire Plan.jpg`}
                alt="Fire Plan"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondSemester;
