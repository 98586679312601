// src/components/Hero.js
import React from 'react';
import useHandleScroll from '../hooks/useHandleScroll';
import './Hero.css'; // Import the CSS file

const Hero = () => {
  const handleScroll = useHandleScroll();

  return (
    <section id="about" className="pt-20 pb-14 bg-gray-100">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
        <div className="flex justify-end md:justify-end bg-gray-100 p-4 image-container">
          <img src="./images/abicut3.png" alt="Abigail Goodman" className="photo-fade-out-bottom text-gray-800 w-600 h-600" />
        </div>
        <div className="md:col-span-2 flex flex-col justify-center bg-gray-100 p-4">
          <h1 className="handwritten-font text-4xl font-bold">Abigail Goodman</h1>
          <p className="mt-4 text-gray-700">Hello! I'm Abigail, an enthusiastic and social 5th-semester student of Architectural Technology and Construction Management with a deep passion for architecture. My father, who works in construction and built our family homes, inspired my career choice. Growing up, I often watched him transform simple materials into beautiful, functional spaces, and I accompanied him to various construction sites, sparking my fascination with the field. These experiences motivated me to pursue this path, and my studies have allowed me to combine my love for design with practical, hands-on experience. My goal is to create sustainable, innovative, and aesthetically pleasing structures that enhance their environments and contribute to a better future.</p>
          <a
            href="#projects"
            className="block w-full"
            onClick={(e) => handleScroll(e, "projects")}
          >
            <button className="button-89 w-full">See my projects</button>
          </a>
        </div>
      </div>
      <section className="py-28 bg-gray-100 bg-building-wireframe bg-cover bg-center text-white text-center">
      </section>
      <section className="py-28 bg-gray-100 bg-building-wireframe bg-cover bg-center text-white text-center inverted">
      </section>
    </section>
  );
};

export default Hero;
