/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import "../../../App.css";
import { Modal } from "flowbite-react";

const ThirdSemester = () => {
  const sections = useRef([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          const navLink = document.querySelector(`a[href="#${id}"]`);
          if (navLink) {
            if (entry.isIntersecting) {
              navLink.classList.remove("text-blue-300");
              navLink.classList.add("text-abiPink");
              entry.target.classList.add("visible");
            } else {
              navLink.classList.remove("text-abiPink");
              navLink.classList.add("text-blue-300");
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);
  const handleNavClick = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative bg-gray-100 text-black px-36">
      <div className="fixed right-0 mr-11 top-1/2 transform -translate-y-1/2 w-48 bg-abiBlue text-white p-4 overflow-y-auto rounded-md">
  <h2 className="text-lg font-bold mb-4 text-slate-200 text-center">
    Navigation
  </h2>
  <ul className="space-y-2 text-sm">
    <li>
      <a
        href="#floorplan"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "floorplan")}
      >
        Floor Plan
      </a>
    </li>
    <li>
      <a
        href="#constructionSitePlan"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "constructionSitePlan")}
      >
        Construction Site Plan
      </a>
    </li>
    <li>
      <a
        href="#sitePlan"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "sitePlan")}
      >
        Site Plan
      </a>
    </li>
    <li>
      <a
        href="#details"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "details")}
      >
        Details
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#hollowCoreGable"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "hollowCoreGable")}
          >
            Hollow Core Gable
          </a>
        </li>
        <li>
          <a
            href="#elevatorShaft"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "elevatorShaft")}
          >
            Elevator Shaft
          </a>
        </li>
        <li>
          <a
            href="#cafeHallwayWallFoundation"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "cafeHallwayWallFoundation")}
          >
            Café Hallway Wall Foundation
          </a>
        </li>
        <li>
          <a
            href="#cornerConcrete"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "cornerConcrete")}
          >
            Corner (Concrete)
          </a>
        </li>
        <li>
          <a
            href="#foundationUnderElevatorShaft"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "foundationUnderElevatorShaft")}
          >
            Foundation Under Elevator Shaft
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a
        href="#productionBooklet"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "productionBooklet")}
      >
        Production Booklet
      </a>
    </li>
    <li>
      <a
        href="#mountingManual"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "mountingManual")}
      >
        Mounting Manual
      </a>
    </li>
    <li>
      <a
        href="#mountingDetail"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "mountingDetail")}
      >
        Mounting Details
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#foundation"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "foundation")}
          >
            Foundation
          </a>
        </li>
        <li>
          <a
            href="#roofGable"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "roofGable")}
          >
            Roof Gable
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>

      
      <Modal show={openModal} size="7xl" onClose={() => setOpenModal(false)}>
        <Modal.Header>Booklet</Modal.Header>
        <Modal.Body>
          <iframe
            allowFullScreen
            className="fp-iframe"
            style={{ border: "1px solid lightgray", width: "100%", height: "500px" }}
            src="https://heyzine.com/flip-book/ad3039cab0.html"
            title="Flip Book"
          />
        </Modal.Body>
      </Modal>

      <Modal show={openModal2} className="h-96" onClose={() => setOpenModal2(false)}>
        <Modal.Header>Mounting Manual</Modal.Header>
        <Modal.Body>
          <iframe
            allowFullScreen
            className="fp-iframe"
            style={{ border: "1px solid lightgray", width: "100%", height: "700px" }}
            src="https://heyzine.com/flip-book/bfd2a8c362.html"
            title="Flip Book"
          />
        </Modal.Body>
      </Modal>

      <div className="pr-56 pl-8 py-8">
        <h1 className="text-3xl font-bold mb-4 text-black text-center mt-8">
          Third Semester
        </h1>
        <p className="mb-8 text-center">
          This semester was centred on creating a multi-purpose sports hall for
          Horsens Municipality focused around concrete elements for the sports
          hall, locker roof and offices as well as wooden elements for the
          cafeteria and offices. The hall must accommodate sports, concerts,
          bingo and theatre for up to 380 people. Once designed we collaborated
          with a team from our class to create a production booklet for their
          wooden components’ as well as a mounting manual. This required clear
          and professional communication with the other group to discuss
          potential issues and ensure clarity in the project. This project
          improved not only my skills in joining wooden and concrete elements
          but also my communication skills.
        </p>
        <div
          className="mb-4 transition-opacity"
          id="floorplan"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <h2 className="text-2xl font-semibold mb-1 text-center">
            Floor Plan
          </h2>
          <h3 className="text-base text-center mb-3">
            We were given the design of the building then improved it in order
            to fulfil regulations
          </h3>
          <img
            src={`${process.env.PUBLIC_URL}/3rd semester/Floor plan.jpg`}
            alt="Floor Plan"
            className="w-full h-auto"
          />
          <p className="text-base text-center mt-3">
            After receiving the initial project we changed the floor plan to
            make the construction more accessible and compliant with the
            regulations
          </p>
          <h3 className="text-xl font-bold text-left mt-3 mb-1">Changes</h3>
          <ul className="list-disc ml-5">
            <li>
              Staircase
              <ul className="list-disc ml-5">
                <li>
                  Moved to the middle of the administration part of the concrete
                  building
                </li>
                <li>
                  Allows the technical room on the first floor to comply with
                  regulations
                </li>
                <li>Removes the necessity for an external staircase</li>
              </ul>
            </li>
            <li>
              Ground Floor Corridor
              <ul className="list-disc ml-5">
                <li>
                  Shortened to fit with fire regulations (not to exceed 60 m)
                </li>
                <li>
                  Removes the necessity to place fire doors in the middle,
                  making the space more welcoming
                </li>
              </ul>
            </li>
            <li>
              Lounges
              <ul className="list-disc ml-5">
                <li>
                  Added three lounges to make the main entrance more welcoming
                  and give the corridor purpose
                </li>
              </ul>
            </li>
            <li>
              Cloakroom
              <ul className="list-disc ml-5">
                <li>Added for guests as it is a multifunctional sports hall</li>
              </ul>
            </li>
            <li>
              Changing Rooms
              <ul className="list-disc ml-5">
                <li>
                  Added two additional for judges, allowing four changing rooms
                  for homes and guests separated into male and female
                </li>
                <li>Three of them are handicapped accessible</li>
              </ul>
            </li>
            <li>
              Toilets
              <ul className="list-disc ml-5">
                <li>
                  More toilets added as the sports hall is designed for 380
                  people
                </li>
                <li>
                  More toilets on the first floor to accommodate meeting rooms
                </li>
                <li>
                  Handicapped toilets placed by the opening of the door for easy
                  access
                </li>
              </ul>
            </li>
            <li>
              First Floor Corridor
              <ul className="list-disc ml-5">
                <li>Extended to give direct access to the technical room</li>
                <li>Provides meeting room privacy</li>
                <li>Gives a clear path with no tables or chairs in the way</li>
              </ul>
            </li>
          </ul>
        </div>

        <div
          className="transition-opacity"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <div className="grid grid-cols-2 gap-4 mt-10">
            <div
              className="col-span-1 transition-opacity"
              id="constructionSitePlan"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-2 text-center">
                Construction Site Plan
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/3rd semester/Construction site plan.jpg`}
                alt="Construction Site Plan"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="sitePlan"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-2 text-center">
                Site Plan
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/3rd semester/Site plan.jpg`}
                alt="Site Plan"
                className="w-full h-auto"
              />
            </div>
          </div>
          <h2
            className="text-2xl text-center font-semibold mt-14"
            id="details"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            Initial Sketches
          </h2>
          <h3 className="text-base text-center">
            Sent to partner wood group who will organise production and mounting
            for concrete
          </h3>
          <div className="grid grid-cols-2 grid-rows-3 gap-4 mt-4">
            <div
              className="col-span-1"
              id="hollowCoreGable"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl text-center font-semibold">
                Hollow Core Element Gable
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/3rd semester/Detail/Hollow core element, gable.jpg`}
                alt="Hollow Core Element Gable"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1"
              id="elevatorShaft"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl text-center font-semibold">
                Elevator Shaft + Hollow Core Floor Partition (Concrete)
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/3rd semester/Detail/Hollow core element, elevator shaft.jpg`}
                alt="Elevator Shaft + Hollow Core Floor Partition (Concrete)"
                className="w-full h-auto"
              />
            </div>
            <div className="col-start-2 row-start-2">
              <div
                className="col-span-1"
                id="cafeHallwayWallFoundation"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-xl text-center font-semibold">
                  Café + Hallway Wall Foundation
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/3rd semester/Detail/Cafe and hallway wall foundation.jpg`}
                  alt="Café + Hallway Wall Foundation"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="col-start-2 row-start-3">
              <div
                className="col-span-1"
                id="cornerConcrete"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-xl text-center font-semibold">
                  Corner (Concrete)
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/3rd semester/Detail/Corner (concrete).jpg`}
                alt="Corner (Concrete)"
                className="w-full h-auto"
              />
              </div>
            </div>
            <div className="row-span-2 col-start-1 row-start-2">
              <div
                className="col-span-1"
                id="foundationUnderElevatorShaft"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-xl text-center font-semibold">
                  Foundation Under Elevator Shaft
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/3rd semester/Detail/Foundation under the elevator shaft.jpg`}
                  alt="Foundation Under Elevator Shaft"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-10">
            <div
              className="col-span-1 transition-opacity"
              id="productionBooklet"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-5 text-center">
                Production Booklet
              </h2>
              
              
              <a className="book-container cursor-pointer" onClick={() => setOpenModal(true)}>
                <div className="book">
                  <img
                    alt="Production Booklet"
                    src={`${process.env.PUBLIC_URL}/3rd semester/Production Booklet/jpg/Booklet - page 1.jpg`}
                  />
                </div>
              </a>
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="mountingManual"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-5 text-center">
                Mounting Manual
              </h2>
              <a className="livro-container cursor-pointer" onClick={() => setOpenModal2(true)}>
                <div className="livro">
                  <img
                    alt="Mounting Manual"
                    src={`${process.env.PUBLIC_URL}/3rd semester/Mounting Manual/jpg/Mounting manual - page 1.jpg`}
                  />
                </div>
              </a>
            </div>
          </div>

          <h3
            className="text-2xl font-semibold mt-20 mb-2 text-center"
            id="mountingDetail"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            Mounting Details
          </h3>
          <div className="grid grid-cols-2 gap-4">
            <div
              className="col-span-1 transition-opacity"
              id="foundation"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Foundation
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/3rd semester/Mounting Detail/Foundation + gable detail.jpg`}
                alt="Foundation"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="roofGable"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Roof Gable
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/3rd semester/Mounting Detail/Connection roof gable parapet.jpg`}
                alt="Roof Gable"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default ThirdSemester;
