// src/App.js
import React from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Contact from './components/Contact';
import FirstSemester from './components/projects/1semester/firstsemester';
import SecondSemester from './components/projects/2semester/secondsemester';
import ThirdSemester from './components/projects/3semester/thirdsmester';
import FourthSemester from './components/projects/4semester/fourthsemester';

const Home = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="App">
      <Hero />
      <Projects />
      
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/first-semester" element={<FirstSemester />} />
          <Route path="/second-semester" element={<SecondSemester />} />
          <Route path="/third-semester" element={<ThirdSemester />} />
          <Route path="/fourth-semester" element={<FourthSemester />} />
        </Routes>
        <Contact />
      </div>
    </Router>
  );
}

export default App;
