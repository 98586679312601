import React from "react";

const Contact = () => {
  return (
    <div id="contact" class="flex items-end w-full bg-white">
      <footer class="w-full text-gray-700 bg-gray-200 body-font">
        <div class="container flex flex-col flex-wrap px-5 py-24 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap">
          <div class="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left">
            <div class="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 class="mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font">
                SKILLS
              </h2>
              <nav class="mb-10 list-none">
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    Hand Drawing
                  </p>
                </li>
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    Communication
                  </p>
                </li>
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    Time Management
                  </p>
                </li>
              </nav>
            </div>
            <div class="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 class="mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font">
                SOFTWARE
              </h2>
              <nav class="mb-10 list-none">
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    Revit
                  </p>
                </li>
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    SketchUp
                  </p>
                </li>
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    Microsoft Office
                  </p>
                </li>
              </nav>
            </div>
            <div class="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 class="mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font">
                STUDIES
              </h2>
              <nav class="mb-10 list-none">
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    VIA University College
                  </p>
                </li>
                <li class="mt-3">
                  <p class="text-gray-500 ">
                    Grenaa Gymnasium
                  </p>
                </li>
              </nav>
            </div>
            <div class="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 class="mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font">
                Contact
              </h2>
              <nav class="mb-10 list-none">
                <li class="mt-3">
                  <a class="text-gray-500 cursor-pointer hover:text-gray-900" href="tel:+44 24 97 70 88">
                    +44 24 97 70 88
                  </a>
                </li>
                <li class="mt-3">
                  <a class="text-gray-500 cursor-pointer hover:text-gray-900" href="mailto:abigail@via.dk">
                    abigail@via.dk
                  </a>
                </li>
              </nav>
      
            </div>
          </div>
        </div>
        <div class="bg-gray-300">
          <div class="container px-5 py-4 mx-auto">
            <a href="https://dlopesa.com" rel="noreferrer" target="_blank">
              <p class="text-sm text-gray-700 xl:text-center">
                © 2024 by dlopesa all rights reserved{" "}
              </p>
            </a>
            <div class="flex justify-center items-center">
              <a
                class="ml-3 text-gray-500 cursor-pointer hover:text-gray-700 text-center"
                href="https://www.linkedin.com/in/danieladriao/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
              <a
                class="ml-3 text-gray-500 cursor-pointer hover:text-gray-700 text-center"
                href="https://dlopesa.com/#home"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-width="3"
                  fill="none"
                  stroke="currentColor"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M39.93,55.72A24.86,24.86,0,1,1,56.86,32.15a37.24,37.24,0,0,1-.73,6"></path>
                    <path d="M37.86,51.1A47,47,0,0,1,32,56.7"></path>
                    <path d="M32,7A34.14,34.14,0,0,1,43.57,30a34.07,34.07,0,0,1,.09,4.85"></path>
                    <path d="M32,7A34.09,34.09,0,0,0,20.31,32.46c0,16.2,7.28,21,11.66,24.24"></path>
                    <line x1="10.37" y1="19.9" x2="53.75" y2="19.9"></line>
                    <line x1="32" y1="6.99" x2="32" y2="56.7"></line>
                    <line x1="11.05" y1="45.48" x2="37.04" y2="45.48"></line>
                    <line x1="7.14" y1="32.46" x2="56.86" y2="31.85"></line>
                    <path d="M53.57,57,58,52.56l-8-8,4.55-2.91a.38.38,0,0,0-.12-.7L39.14,37.37a.39.39,0,0,0-.46.46L42,53.41a.39.39,0,0,0,.71.13L45.57,49Z"></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
