import React from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "@react-spring/web";
import { Link } from "react-router-dom";

const ProjectItem = ({ direction, children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animationProps = useSpring({
    transform: inView
      ? "translateX(0)"
      : direction === "left"
      ? "translateX(-20%)"
      : "translateX(20%)",
    opacity: inView ? 1 : 0,
    config: { tension: 280, friction: 60 },
  });

  return (
    <animated.div
      ref={ref}
      style={animationProps}
      className="flex justify-between items-center w-full"
    >
      {children}
    </animated.div>
  );
};

const Projects = () => {
  return (
    <section id="projects" className="pb-20 bg-gray-100">
      <div className="eight  mb-8 flex justify-center font-thin">
        <h1>PROJECTS</h1>
      </div>
      <div className="space-y-8 w-full px-10">
        {/* Project Item 1 */}
        <ProjectItem direction="left">
          <div className=" p-4 flex-grow text-center md:text-left flex items-center justify-center md:justify-start md:w-7/10 w-full">
            <span className="text-gray-800">
              <h2 className="text-lg font-semibold">
                Semester 4: Multi Storey Apartment
              </h2>
              <h2 className="text-base font-medium">
                Skolegade 10-15, 8700 Horsens
              </h2>
              <p className="text-base">
                The aim of this project was to create a subsidised multi storey
                apartment building in Horsens. This building has three stories,
                with four apartments on each storey and a basement which
                contains the technical room and a storage locker for each
                apartment. This was a challenge as there was a large difference
                in terrain levels as well as solving structural details of a
                three storey building. To solve this, the building is made up of
                two separate parts with a 0.5 m difference between the two and
                connected by stairs with a 0.5 m difference in landings.
              </p>
              <Link to="/fourth-semester">
                <button className="group relative inline-flex h-9 items-center justify-center overflow-hidden rounded-md bg-abiBlue px-4 font-medium text-neutral-200 text-sm transition hover:scale-110">
                  <span>See More</span>
                  <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-1000 group-hover:[transform:skew(-12deg)_translateX(100%)]">
                    <div className="relative h-full w-8 bg-white/20"></div>
                  </div>
                </button>
              </Link>
            </span>
          </div>
          <div className=" p-4 flex items-end justify-end md:w-3/10 w-full">
            <img
              src="./images/projects/thumbnails/4th semester.jpg"
              alt="Multi Storey Apartment"
              className="w-full h-auto"
            />
          </div>
        </ProjectItem>
        {/* Project Item 2 */}
        <ProjectItem direction="right">
          <div className=" p-4 flex-grow text-center md:text-left flex items-center justify-center md:justify-start md:w-3/10 w-full">
            <img
              src="./images/projects/thumbnails/3rd semester.jpg"
              alt="Multi Storey Apartment"
              className="w-full h-auto"
            />
          </div>
          <div className=" p-4 flex items-end justify-end md:w-7/10 w-full">
            <span className="text-gray-800">
              <h2 className="text-lg font-semibold">
                Semester 3: Multi Purpose Sports Hall
              </h2>
              <h2 className="text-base font-medium">
                Bygholm Lake, 8700 Horsens
              </h2>
              <p className="text-base">
                In this project we were tasked to design a multi purpose sports
                hall made of concrete including a café area made of wood . The
                biggest difficulty of this design was the connection between the
                concrete and wood sections.
              </p>
              <Link to="/third-semester">
                <button className="group relative inline-flex h-9 items-center justify-center overflow-hidden rounded-md bg-abiBlue px-4 font-medium text-neutral-200 text-sm transition hover:scale-110">
                  <span>See More</span>
                  <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-1000 group-hover:[transform:skew(-12deg)_translateX(100%)]">
                    <div className="relative h-full w-8 bg-white/20"></div>
                  </div>
                </button>
              </Link>
            </span>
          </div>
        </ProjectItem>
        {/* Project Item 3 */}
        <ProjectItem direction="left">
          <div className=" p-4 flex-grow text-center md:text-left flex items-end justify-end md:justify-end md:w-7/10 w-full">
            <span className="text-gray-800">
              <h2 className="text-lg font-semibold">
                Semester 2: Two Storey House with Basement
              </h2>
              <p className="text-base">
                The purpose of this project was to create a multi storey house
                with a basement for a family of four. In this project the
                biggest challenges were the structural challenges related to the
                inclusion of the basement in the project.
              </p>
              <Link to="/second-semester">
                <button className="group relative inline-flex h-9 items-center justify-center overflow-hidden rounded-md bg-abiBlue px-4 font-medium text-neutral-200 text-sm transition hover:scale-110">
                  <span>See More</span>
                  <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-1000 group-hover:[transform:skew(-12deg)_translateX(100%)]">
                    <div className="relative h-full w-8 bg-white/20"></div>
                  </div>
                </button>
              </Link>
            </span>
          </div>
          <div className=" p-4 flex items-end justify-end md:w-3/10 w-full">
            <img
              src="./images/projects/thumbnails/2nd semester.jpg"
              alt="Multi Storey Apartment"
              className="w-full h-auto"
            />
          </div>
        </ProjectItem>
        {/* Project Item 4 */}
        <ProjectItem direction="right">
          <div className=" p-4 flex-grow text-center md:text-left flex items-center justify-center md:justify-start md:w-3/10 w-full">
            <img
              src="./images/projects/thumbnails/1st semester.jpg"
              alt="Multi Storey Apartment"
              className="w-full h-auto"
            />
          </div>
          <div className=" p-4 flex items-start justify-start md:w-7/10 w-full">
            <span className="text-gray-800">
              <Link to="/first-semester">
                <h2 className="text-lg font-semibold">
                  Semester 1: Single Family House
                </h2>
              </Link>
              <p className="text-base">
                During this project we were tasked to create a single floor
                family house. This project posed a lot of challenges as it was
                the first time I had designed and solved a house design.
              </p>
              <Link to="/first-semester">
                <button className="group relative inline-flex h-9 items-center justify-center overflow-hidden rounded-md bg-abiBlue px-4 font-medium text-neutral-200 text-sm transition hover:scale-110">
                  <span>See More</span>
                  <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-1000 group-hover:[transform:skew(-12deg)_translateX(100%)]">
                    <div className="relative h-full w-8 bg-white/20"></div>
                  </div>
                </button>
              </Link>
            </span>
          </div>
        </ProjectItem>
      </div>
    </section>
  );
};

export default Projects;
