import React, { useEffect, useRef } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "../../../App.css";

const FirstSemester = () => {
  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          const navLink = document.querySelector(`a[href="#${id}"]`);
          if (entry.isIntersecting) {
            navLink.classList.remove("text-blue-300");
            navLink.classList.add("text-abiPink");
          } else {
            navLink.classList.remove("text-abiPink");
            navLink.classList.add("text-blue-300");
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);
  const handleNavClick = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative bg-gray-100 text-black px-36">
<div className="fixed right-0 mr-11 top-1/2 transform -translate-y-1/2 w-48 bg-abiBlue text-white p-4 overflow-y-auto rounded-md">
  <h2 className="text-lg font-bold mb-4 text-slate-200 text-center">
    Navigation
  </h2>
  <ul className="space-y-2 text-sm">
    <li>
      <a
        href="#floor-plan"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "floor-plan")}
      >
        Floor Plan
      </a>
    </li>
    <li>
      <a
        href="#modular-analysis"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "modular-analysis")}
      >
        Modular Analysis
      </a>
    </li>
    <li>
      <a
        href="#ventilation"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "ventilation")}
      >
        Ventilation
      </a>
    </li>
    <li>
      <a
        href="#section"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "section")}
      >
        Section
      </a>
    </li>
    <li>
      <a
        href="#details"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "details")}
      >
        Details
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#window"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "window")}
          >
            Window
          </a>
        </li>
        <li>
          <a
            href="#foundation"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "foundation")}
          >
            Foundation
          </a>
        </li>
        <li>
          <a
            href="#door"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "door")}
          >
            Door
          </a>
        </li>
        <li>
          <a
            href="#eave"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "eave")}
          >
            Eave
          </a>
        </li>
        <li>
          <a
            href="#ridge"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "ridge")}
          >
            Ridge
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>


      <div className="pr-56 pl-8 py-8">
        <h1 className="text-3xl font-bold mb-4 text-black text-center mt-8">
          First Semester
        </h1>
        <p className="mb-8 text-center">
          During this semester I was first introduced to Revit. This project is
          centred on creating a single family house for a family of four. It
          draws on inspiration from Danish architecture blending traditional
          elements with modern design. The design creates harmony with open
          common spaces blended with private rooms.
        </p>

        <div className="flex flex-wrap gap-8 mb-8 mt-10">
          <div
            className="flex-1 w-96 h-96"
            id="floor-plan"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Floor Plan
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/1st semester/Floor Plan.jpg`}
              alt="Floor Plan"
              className="w-full h-auto"
            />
          </div>

          <div
            className="flex-1 w-96 h-96"
            id="modular-analysis"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Modular Analysis
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/1st semester/Modular Analysis.jpg`}
              alt="Modular Analysis"
              className="w-full h-auto"
            />
          </div>

          <div
            className="flex-1 w-96 h-96"
            id="ventilation"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Ventilation
            </h2>
            <Slide>
              <div className="each-slide relative">
                <div className="absolute inset-0 flex items-start justify-center text-black text-lg pt-3">
                  Ventilation - page 1
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/1st semester/Ventilation/Ventilation - page 1.jpg`}
                  alt="Ventilation - page 1"
                  className="w-full h-auto"
                />
              </div>
              <div className="each-slide relative">
                <div className="absolute inset-0 flex items-start justify-center text-black text-lg pt-3">
                  Ventilation - page 2
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/1st semester/Ventilation/Ventilation - page 2.jpg`}
                  alt="Ventilation - page 2"
                  className="w-full h-auto"
                />
              </div>
              <div className="each-slide relative">
                <div className="absolute inset-0 flex items-start justify-center text-black text-lg pt-3">
                  Ventilation - page 3
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/1st semester/Ventilation/Ventilation - page 3.jpg`}
                  alt="Ventilation - page 3"
                  className="w-full h-auto"
                />
              </div>
            </Slide>
          </div>
        </div>

        <div
          className="mt-80 mb-4"
          id="section"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <h2 className="text-2xl font-semibold mb-4 text-center">Section</h2>
          <img
            src={`${process.env.PUBLIC_URL}/1st semester/Section.jpg`}
            alt="Section"
            className="w-full h-auto"
          />
        </div>
        <div
          id="details"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <h2 className="text-2xl font-semibold mb-2 text-center">Details</h2>
          <div className="grid grid-cols-3 gap-4">
            <div
              className="col-span-1"
              id="window"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl font-semibold mb-2 text-center">Window</h2>
              <img
                src={`${process.env.PUBLIC_URL}/1st semester/Detail/Window.jpg`}
                alt="Window"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1"
              id="foundation"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl font-semibold mb-2 text-center">
                Foundation
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/1st semester/Detail/Foundation.jpg`}
                alt="Foundation"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 row-span-2"
              id="door"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl font-semibold mb-2 text-center">Door</h2>
              <img
                src={`${process.env.PUBLIC_URL}/1st semester/Detail/Door.jpg`}
                alt="Door"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1"
              id="eave"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl font-semibold mb-2 text-center">Eave</h2>
              <img
                src={`${process.env.PUBLIC_URL}/1st semester/Detail/Eave.jpg`}
                alt="Eave"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1"
              id="ridge"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-xl font-semibold mb-2 text-center">Ridge</h2>
              <img
                src={`${process.env.PUBLIC_URL}/1st semester/Detail/Ridge.jpg`}
                alt="Ridge"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSemester;
