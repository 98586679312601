// src/hooks/useHandleScroll.js
import { useNavigate } from "react-router-dom";

const useHandleScroll = () => {
  const navigate = useNavigate();

  const handleScroll = (e, id) => {
    e.preventDefault();
    if (window.location.pathname !== "/") {
      navigate("/", { state: { scrollTo: id } });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return handleScroll;
};

export default useHandleScroll;
