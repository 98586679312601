import React, { useEffect, useRef } from "react";
import "react-slideshow-image/dist/styles.css";
import "../../../App.css";

const FourthSemester = () => {
  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          const navLink = document.querySelector(`a[href="#${id}"]`);
          if (navLink) {
            if (entry.isIntersecting) {
              navLink.classList.remove("text-blue-300");
              navLink.classList.add("text-abiPink");
              entry.target.classList.add("visible");
            } else {
              navLink.classList.remove("text-abiPink");
              navLink.classList.add("text-blue-300");
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sections.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);
  const handleNavClick = (e, id) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative bg-gray-100 text-black px-36">
      <div className="fixed right-0 mr-11 top-1/2 transform -translate-y-1/2 w-48 bg-abiBlue text-white p-4 overflow-y-auto rounded-md">
  <h2 className="text-lg font-bold mb-4 text-slate-200 text-center">
    Navigation
  </h2>
  <ul className="space-y-2 text-sm">
    <li>
      <a
        href="#floorplan"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "floorplan")}
      >
        Floor Plan
      </a>
    </li>
    <li>
      <a
        href="#terrain"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "terrain")}
      >
        Terrain
      </a>
    </li>
    <li>
      <a
        href="#light"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "light")}
      >
        Light
      </a>
    </li>
    <li>
      <a
        href="#sections"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "sections")}
      >
        Sections
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#section-a-a"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "section-a-a")}
          >
            Section A-A
          </a>
        </li>
        <li>
          <a
            href="#section-b-b"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "section-b-b")}
          >
            Section B-B
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a
        href="#details"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "details")}
      >
        Details
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#wet-room-slope"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "wet-room-slope")}
          >
            Wet Room Slope
          </a>
        </li>
        <li>
          <a
            href="#corridor-main-roof-connection"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "corridor-main-roof-connection")}
          >
            Corridor & Main Roof Connection
          </a>
        </li>
        <li>
          <a
            href="#roof-cricket"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "roof-cricket")}
          >
            Roof Cricket
          </a>
        </li>
        <li>
          <a
            href="#wet-room-drain"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "wet-room-drain")}
          >
            Wet Room Drain
          </a>
        </li>
        <li>
          <a
            href="#eave"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "eave")}
          >
            Eave
          </a>
        </li>
        <li>
          <a
            href="#clt-door"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "clt-door")}
          >
            CLT Door
          </a>
        </li>
        <li>
          <a
            href="#wet-room-wall"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "wet-room-wall")}
          >
            Wet Room Wall
          </a>
        </li>
        <li>
          <a
            href="#ridge"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "ridge")}
          >
            Ridge
          </a>
        </li>
        <li>
          <a
            href="#shaft"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "shaft")}
          >
            Shaft
          </a>
        </li>
        <li>
          <a
            href="#fire-wall"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "fire-wall")}
          >
            Fire Wall
          </a>
        </li>
        <li>
          <a
            href="#nonloadbearingwallclt"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "nonloadbearingwallclt")}
          >
            Non-Load Bearing Wall CLT
          </a>
        </li>
        <li>
          <a
            href="#nonloadbearingwallconcrete"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "nonloadbearingwallconcrete")}
          >
            Non-Load Bearing Wall Concrete
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a
        href="#fire"
        className="hover:text-abiGrey"
        onClick={(e) => handleNavClick(e, "fire")}
      >
        Fire
      </a>
      <ul className="ml-4 mt-2 space-y-1">
        <li>
          <a
            href="#fire-analysis"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "fire-analysis")}
          >
            Fire Analysis
          </a>
        </li>
        <li>
          <a
            href="#fire-compartment-section-plan"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "fire-compartment-section-plan")}
          >
            Fire Compartment and Section Plan
          </a>
        </li>
        <li>
          <a
            href="#fire-escape-plan"
            className="hover:text-abiGrey"
            onClick={(e) => handleNavClick(e, "fire-escape-plan")}
          >
            Fire Escape Plan
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>


      <div className="pr-56 pl-8 py-8">
        <h1 className="text-3xl font-bold mb-4 text-black text-center mt-8">
          Fourth Semester
        </h1>
        <p className="mb-8 text-center">
          In the 4th Semester we were tasked to design a subsidised multi storey
          apartment building for our clients Horsens Municipality. It was
          required that there were 2 room and 3 room homes with an approximate
          area of 55-90 m2 as well as a basement for storage. These rooms had to
          be designed with light and privacy in mind, meaning that they had to
          be shielded from the neighbours while allowing maximum light. It was
          also important that the design fit in with the surrounding environment
          which is a traditional Danish design.
        </p>
        <div
          className="mb-4 transition-opacity"
          id="floorplan"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/4th semester/Poster.png`}
            alt="Floor Plan"
            className="w-full h-auto"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <div
            className="col-span-1 transition-opacity px-10 text-justify"
            id="terrain"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">Terrain</h2>
            <p className="">
              The slope of the terrain posed a challenge, with a 2.5 m
              difference from the northern to the southern part of the plot. In
              order to overcome this, we have made several design features:
            </p>
            <ul className="list-disc list-inside space-y-2 ml-3">
              <li>
                The building is divided into two parts with a 0.5 m height
                difference.
              </li>
              <li>
                Stairs and an elevator are located on the outer center of the
                building, with 0.5 m height differences between landings to
                service both building parts.
              </li>
            </ul>
          </div>
          <div
            className="col-span-1 transition-opacity px-10 text-justify"
            id="light"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">Light</h2>
            <p>
              <b>Balconies</b> have been placed on the west façade in order to
              maximise evening light<br></br>
              <b>Windows</b> on the west façade have shutters that can be
              dragged across to create shade and privacy
            </p>
          </div>
        </div>

        <h1
          className="text-center text-2xl font-bold mt-20"
          id="sections"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          Sections
        </h1>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <div
            className="col-span-1 transition-opacity px-10 text-justify"
            id="section-a-a"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Section A-A
            </h2>

            <img
              src={`${process.env.PUBLIC_URL}/4th semester/Section/Section a-a.jpg`}
              alt="Section A-A"
              className="w-full h-auto"
            />
          </div>
          <div
            className="col-span-1 transition-opacity px-10 text-justify"
            id="section-b-b"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Section B-B
            </h2>
            <img
              src={`${process.env.PUBLIC_URL}/4th semester/Section/Section b-b.jpg`}
              alt="Section B-B"
              className="w-full h-auto"
            />
          </div>
        </div>

        <div
          id="details"
          className="mt-10 transition-opacity"
          ref={(el) => {
            if (el) sections.current.push(el);
          }}
        >
          <h2 className="text-2xl font-bold text-center mt-20">Details</h2>
          <h3 className="text-xl font-semibold mb-1 text-center mt-6">
            Climate Shell - wind tight, water tight
          </h3>
          <div className="grid grid-cols-3 gap-4">
            <div
              className="col-span-1 transition-opacity"
              id="wet-room-slope"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Wet Room Slope
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Wet room slope.jpg`}
                alt="Wet Room Slope"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="corridor-main-roof-connection"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Corridor and Main Roof Connection
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Corridor & main roof connection.jpg`}
                alt="Corridor and Main Roof Connection"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="roof-cricket"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Roof Cricket
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Roof cricket.jpg`}
                alt="Roof Cricket"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="wet-room-drain"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Wet Room Drain
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Wet room drain.jpg`}
                alt="Wet Room Drain"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="eave"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">Eave</h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Eave.jpg`}
                alt="Eave"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 row-span-2 transition-opacity"
              id="clt-door"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                CLT Door
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/CLT door.jpg`}
                alt="CLT Door"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="wet-room-wall"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">
                Wet Room Wall
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Wet room wall.jpg`}
                alt="Wet Room Wall"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity"
              id="ridge"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-lg font-semibold mb-2 text-center">Ridge</h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Details/Ridge.jpg`}
                alt="Ridge"
                className="w-full h-auto"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-10" id="load-transferring">
            <div className="col-span-1">
              <h3 className="text-xl font-semibold mb-1 text-center">
                Load Transferring
              </h3>
              <div
                className="transition-opacity"
                id="shaft"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-lg font-semibold mb-2 text-center">
                  Shaft
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/4th semester/Details/Shaft.jpg`}
                  alt="Shaft"
                  className="w-full h-auto"
                />
              </div>
              <div
                className="transition-opacity mt-4"
                id="fire-wall"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-lg font-semibold mb-2 text-center">
                  Fire Wall
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/4th semester/Details/Fire wall.jpg`}
                  alt="Fire Wall"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="col-span-1">
              <h3 className="text-xl font-semibold mb-1 text-center">
                Aesthetic
              </h3>
              <div
                className="transition-opacity"
                id="nonloadbearingwallclt"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-lg font-semibold mb-2 text-center">
                  Non-Load Bearing Internal Wall CLT
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/4th semester/Details/Non-load bearing internal wall clt.jpg`}
                  alt="Non-Load Bearing Internal Wall CLT"
                  className="w-full h-auto"
                />
              </div>
              <div
                className="transition-opacity mt-4"
                id="nonloadbearingwallconcrete"
                ref={(el) => {
                  if (el) sections.current.push(el);
                }}
              >
                <h2 className="text-lg font-semibold mb-2 text-center">
                  Non-Load Bearing Internal Wall Concrete
                </h2>
                <img
                  src={`${process.env.PUBLIC_URL}/4th semester/Details/Non-load bearing internal wall concrete.jpg`}
                  alt="Non-Load Bearing Internal Wall Concrete"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-bold text-center mt-20">Fire</h2>
          <div
            className="grid grid-cols-3 gap-4 mt-5"
            id="fire"
            ref={(el) => {
              if (el) sections.current.push(el);
            }}
          >
            <div
              className="col-span-1 transition-opacity px-10 text-justify"
              id="fire-analysis"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-2 text-center">
                Fire Analysis
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Fire/Fire analysis.jpg`}
                alt="Fire Analysis"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity px-10 text-justify"
              id="fire-compartment-section-plan"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-2 whitespace-nowrap">
                Fire Compartment and Section Plan
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Fire/Fire compartment and section.jpg`}
                alt="Fire Compartment and Section Plan"
                className="w-full h-auto"
              />
            </div>
            <div
              className="col-span-1 transition-opacity px-10 text-justify"
              id="fire-escape-plan"
              ref={(el) => {
                if (el) sections.current.push(el);
              }}
            >
              <h2 className="text-2xl font-semibold mb-2 text-center">
                Fire Escape Plan
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/4th semester/Fire/Fire escape plan.jpg`}
                alt="Fire Escape Plan"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthSemester;
