import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Hero.css"; // Import the CSS file

const Navbar = () => {
  const navigate = useNavigate();

  const handleScroll = (e, id) => {
    e.preventDefault();
    if (window.location.pathname !== "/") {
      navigate("/", { state: { scrollTo: id } });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <nav className="bg-abiBlue p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <div className="text-abiPink font-bold text-lg handwritten-font ">
            Abigail Goodman
          </div>
        </Link>
        <div className="space-x-4">
          <a
            href="#about"
            className="text-white hover:text-gray-300"
            onClick={(e) => handleScroll(e, "about")}
          >
            About Me
          </a>
          <a
            href="#projects"
            className="text-white hover:text-gray-300"
            onClick={(e) => handleScroll(e, "projects")}
          >
            Projects
          </a>
          <a
            href="#contact"
            className="text-white hover:text-gray-300"
            onClick={(e) => handleScroll(e, "contact")}
          >
            Contact
          </a>
          <a
            href={`${process.env.PUBLIC_URL}/Abigail Goodman CV.pdf`}
            className="text-white hover:text-gray-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="group relative inline-flex h-8 items-center justify-center overflow-hidden rounded-md bg-abiPink px-4 font-medium text-abiBlue duration-500">
              <div className="translate-y-0 opacity-100 transition group-hover:-translate-y-[150%] group-hover:opacity-0">
                Resume
              </div>
              <div className="absolute translate-y-[150%] opacity-0 transition group-hover:translate-y-0 group-hover:opacity-100">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                >
                  <path
                    d="M7.5 2C7.77614 2 8 2.22386 8 2.5L8 11.2929L11.1464 8.14645C11.3417 7.95118 11.6583 7.95118 11.8536 8.14645C12.0488 8.34171 12.0488 8.65829 11.8536 8.85355L7.85355 12.8536C7.75979 12.9473 7.63261 13 7.5 13C7.36739 13 7.24021 12.9473 7.14645 12.8536L3.14645 8.85355C2.95118 8.65829 2.95118 8.34171 3.14645 8.14645C3.34171 7.95118 3.65829 7.95118 3.85355 8.14645L7 11.2929L7 2.5C7 2.22386 7.22386 2 7.5 2Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </button>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
